const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

    STATUS_RUNNING: 3,

    SENDING_TYPE_IMMEDIATE: 1,
    SENDING_TYPE_SCHEDULE: 2,
    SENDING_TYPE_Fixed: 3,
    SENDING_TYPE_GREETINGS: 4,
    SENDING_TYPE_RECURRING: 5,

    MESSAGE_TYPE_SMS: 1,
    MESSAGE_TYPE_EMAIL: 4,
    MESSAGE_TYPE_VOICE: 3,
    MESSAGE_TYPE_VIDEO_EMAIL: 14,

    VIDEO_STATUS_WELCOME: 0,
    VIDEO_STATUS_WEBCAM: 1,
    VIDEO_STATUS_WARNING: 2,
    VIDEO_STATUS_PREVIEW: 3,

    MESSAGE_TEMPLATE_VIDEO_EMAIL: 4,
    Timeline:{
        CONTENT_TYPE_SMS : 1,
        CONTENT_TYPE_MMS : 2,
        CONTENT_TYPE_VOICE : 3,
        CONTENT_TYPE_EMAIL : 4,
        CONTENT_TYPE_CALL : 5,
        CONTENT_TYPE_ACTIVITY : 6,
        CONTENT_TYPE_OUTGOING_CALL : 7,
        CONTENT_TYPE_WEBHOOK : 9,
        CONTENT_TYPE_STAGE : 10,
        CONTENT_TYPE_APPOINTMENT : 12,
        CONTENT_TYPE_CALL_BRIGDE : 13,
        CONTENT_TYPE_VIDEO_EMAIL : 14,
        CONTENT_TYPE_CHAT : 15,
        CONTENT_TYPE_NOTE : 16,
        CONTENT_TYPE_GENERAL : 20,
        CONTENT_TYPE_POST_CARD : 21,
        CONTENT_TYPE_GREETINGS_CARD : 22,
        CONTENT_TYPE_GIFT : 23,
        CONTENT_TYPE_LETTER : 24,
        CONTENT_TYPE_CALL_RECORD : 25,


        INOUT_TYPE_IN : 1,
        INOUT_TYPE_OUT : 2,

        READ :1,
        UNREAD :0,

        STATUS_FAIL :2,
        STATUS_SUCCESS:1,
        STATUS_PENDING : 0,
        STATUS_SENT :3,
        STATUS_UNDELIVERED :4,
        STATUS_ACCEPTED : 5,
        STATUS_DELETED : 6,

        SENT_IMMEDIATLY : 1,
        SENT_SCHEDULE : 2,


        CONTENT_TYPES : {
            0: 'All Conversation',
           1 : 'SMS',
           2 : 'MMS',
           3 : 'Voice',
           4 : 'Email',
           5 : 'Call',
           6 : 'Activity',
           7 : 'Outgoing Call',
           9 : 'Webhook',
           10 : 'Stage',
           12 : 'Appointment',
           13 : 'Call Bridge',
           14 : 'Video Email',
           15 : 'Chat',
           16 : 'Note',
           20 : 'General',
           25 : 'Call Record',
           21 : 'Postcard',
           22 : 'Greeting Card',
           23 : 'Gift',
           24 : 'Letter',
        }
    }
}

export const CampaignSettingTypes = {
   SMS : 1,
   MMS : 2,
   VOICE : 3,
   EMAIL : 4,
   CALL : 5,
   ACTIVITY : 6,
   ACTION : 7,
   FB_AUDIENCE : 8,
   WEBHOOK : 9,
   VIDEO_EMAIL : 14,
   POSTCARD : 21,
   GREETINGCARD : 22,
   GIFTCARD : 23,
   LETTER : 24,
}

export const ActivityType = {
    CALL : '1',
    APOINTMENT : '2',
    TASK: '3',
    DEADLINE: '4',
    EMAIL: '5',
    FOLLOWUP: '6',
    OTHERS: '7'

}

export const CustomFieldType = {
    TEXT : 1,
    MULTILINE_TEXT : 2,
    NUMERIC : 3,
    DATE : 4,
    CHECKBOX : 5,
    SELECT : 6,
    RADIO : 7,
    PHONE : 8,
    ZIP_CODE : 9,
    WEBSITE : 10
}

export default CoreConstants;

export const EMPTY_DATA_IMG = "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png";